.main_body_wrapper {
  height: 100vh;
  background-position: center;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: scroll;
}

.text_wrapper{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing_page_text_color{
  padding-top: 30%;
  text-align: center;
  font-size: 150%;
}

.landing_page_button{
}

.sign_up_button{
  display: flex;
  justify-content: center;
}

.log_in_button{
  display: flex;
  justify-content: center;
}

.landingCard{
  padding: 35px;
  border: none;
  background-color: rgba(36, 36, 36, 0);
  height: 60vh;
  margin: 50px;
  color:rgb(137, 192, 174);
  backdrop-filter: blur(3px);
}

@media screen and (max-width: 300px) {
  .mobileBody {
    font-size: 100%;
    width: 100vw;
  }
}