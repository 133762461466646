.faq_main_body_wrapper {
  height: 90vh;
  background-position: center;
  background-size: contain;
  overflow:auto;
}

.faq_text_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

.faqCard {
  background-color: rgba(193, 148, 0, 0.424);
  color: white;
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
}

.faqh{
  color: rgb(137, 192, 174);
}