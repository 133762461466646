.dv_main_body_wrapper {
  height: 90vh;
  background-position: center;
  background-size: contain;
}

.dv_text_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.verseCard{
  background-color: rgba(189, 126, 8, 0.251);
  color: rgb(137, 192, 174);
  border: none;
  border-radius: 15px;
  backdrop-filter: blur(5px);
}