.note_main_body_wrapper {
  height: 91vh;
  background-position: center;
  background-size: contain;
  background-color: rgba(255, 255, 255, 0.251);
}

.contact_text_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contactInfoCard {
  background-color: rgba(119, 232, 255, 0.424);
  color: white;
  border: none;
  box-shadow: 4px;
  border-radius: 15px;
  backdrop-filter: blur(5px);
}

.noteContainer {
  backdrop-filter: blur(5px);
}