.bible_main_body_wrapper {
  background-position: center;
  background-size: contain;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
}

.text_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bibleList{
  color: rgb(0, 144, 67);
  padding: 5px;
  backdrop-filter: blur(5px);
  text-align: center;
  border-radius: 15px;
  font-size: 1.5rem;
}

.bibleHead {
  color: rgb(0, 145, 60);
  text-align: center;
  text-decoration: underline;
  font-size: 5rem;
}