@media screen and (max-width: 355px){
  .headerNavbar{
    width: 100vw;
  }

  .mobileBody {
    height: 159vh;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 355px){
  .headerNavbar {
    width: 178vw;
  }
  .navbarFooter {
    width: 178vw;
  }

} */