.login_main_body_wrapper {
  height: 90vh;
  background-position: center;
  background-size: contain;
  background-color: rgba(255, 255, 255, 0.251);
  overflow: hidden;
}

.login_text_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  backdrop-filter: blur(5px);
}

.loginCard {
  background-color: rgba(255, 255, 255, 0.323);
  backdrop-filter: blur(5px);
}

.loginh {
  color: rgb(137, 192, 174);
}